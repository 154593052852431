import {useCreateInterviewAndTimestampMutation} from 'src/graphql-generated'
import useViewer from 'src/hooks/useViewer'

const useTrackInterviewInteraction = () => {
  const {loggedIn} = useViewer()
  const [createInterviewAndTimestamp] = useCreateInterviewAndTimestampMutation()

  const trackInterviewInteraction = (eventType: string) => {
    if (!loggedIn) {
      return
    }
    createInterviewAndTimestamp({variables: {eventType}})
  }

  return trackInterviewInteraction
}

export default useTrackInterviewInteraction
