// import {Location} from '@reach/router'
import {navigate} from 'gatsby'
// import queryString from 'query-string'

import {WindowLocationWithReturnTo} from 'src/types'

export const rem = (px: number, base: number = 16): string =>
  `${(px / base).toFixed(4)}rem`

export const goBack = (
  location?: WindowLocationWithReturnTo,
  fallbackUrl?: string
) => {
  const returnTo = location?.state?.returnTo

  if (returnTo) {
    return navigate(returnTo)
  }

  if (fallbackUrl) {
    return navigate(fallbackUrl)
  }

  window.history.go(-1)
}

export const pluralize = (str: string, n: number, plural?: string) =>
  n === 1 ? str : plural || `${str}s`

export const isLmsImplementation = (location = {}) => {
  if (typeof window === 'undefined') return false
  console.log('ignore', location)
  // const locationParsed = queryString.parse(location.search)
  if (window.location.search.includes('lms=true')) {
    return true
  }
  return false
}

export function truncateString(str: string, maxLength = 200, ellipsis = '...') {
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + ellipsis
  } else {
    return str
  }
}

export function commaSeparateNumber(number: number): string {
  let numberString: string = number.toString()

  numberString = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return numberString
}

export function extractVideoId(videoUrl: string): string {
  const videoIdRegex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
  const match = videoUrl.match(videoIdRegex)
  if (match) {
    return match[1]
  } else {
    const urlParams = new URLSearchParams(videoUrl.split('?')[1])
    const videoId = urlParams.get('v')
    return videoId ? videoId : ''
  }
}

export interface AddUserRelativResponse {
  status: string
  email?: string
}

export async function addUserRelativ(
  clientId: string | undefined | null,
  email: string | undefined
): Promise<AddUserRelativResponse> {
  if (!email) {
    return {status: 'fail'}
  }

  const tokenUrl = '/v2/relativ_jwt'

  try {
    const authResponse = await fetch(tokenUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
      },
      body: JSON.stringify({
        client_id: clientId,
        email: email,
      }),
    })

    if (authResponse.ok || authResponse.status === 400) {
      const responseData = await authResponse.json()
      console.log('User authentication response:', responseData.message)
      return {status: 'success', email: email}
    } else {
      throw new Error('Failed to authenticate')
    }
  } catch (error) {
    console.error('Error:', error)
    return {status: 'fail'}
  }
}

export function parseErrorString(errorString: string) {
  const regex =
    /{.*"code.*":.*"(.*?)\\.*",.*"message.*":.*"(.*?)\\.*",.*"statusCode.*":(\d+?)}/
  const match = errorString.match(regex)

  if (match) {
    const errorCode = match[1]
    const errorMessage = match[2]
    const statusCode = parseInt(match[3])
    return {errorCode, errorMessage, statusCode}
  } else {
    return null
  }
}

export const formatDate = (utcDate: string): string | null => {
  const isoDate = utcDate.replace(' ', 'T').replace(' UTC', 'Z')

  const parsedDate = new Date(isoDate)
  if (!utcDate || isNaN(parsedDate.getTime())) {
    console.error('Invalid date value:', utcDate)
    return ""
  }

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  }

  return new Intl.DateTimeFormat('en-US', options).format(parsedDate)
}

export const downloadReport = async () => {
  try {
    const response = await fetch(`/v2/download_report`, {
      method: 'GET',
    })

    if (!response.ok) {
      const errorData = await response.json()
      throw new Error(errorData.error || 'Error downloading the report')
    }

    const blob = await response.blob()
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'report.zip')
    document.body.appendChild(link)
    link.click()
    link.remove()

    return {success: true}
  } catch (error: any) {
    return {success: false, message: error.message}
  }
}
